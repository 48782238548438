<template>
  <section id="ContactUs" class="p-5 bg-light">
    <div class="container">
      <div class="row align-items-center text-center text-md-start">
        <div class="col-md-6 mb-4 mb-md-0">
          <h1 class="display-5 mb-4">
            Get in touch with our<br />
            <span class="text-warning fw-bold">team.</span>
          </h1>
          <p class="lead">
            Medical supplies are the most fundamental part of the healthcare
            experience. We’re ready to work together to create a foundation that
            supports your organization’s healing work.
          </p>
        </div>
        <div class="col-md-6">
          <b-button variant="warning" size="lg" pill @click="showModal = true" class="shadow-sm btn-lg">
            Contact Us
          </b-button>
        </div>
      </div>
      <b-modal class="contactFormModal" v-model="showModal" title="Contact Us" @ok="handleSubmit" hide-footer centered style="color:blue; ;">
        <b-form @submit.prevent="handleSubmit">
          <b-form-group id="nameFormGroup" label="Name" label-for="name" label-cols-sm="2" label-align-sm="right">
            <b-form-input v-model="form.name" type="text" id="name" required></b-form-input>
          </b-form-group>
          <b-form-group id="companyFormGroup" label="Company" label-for="company" label-cols-sm="2"
            label-align-sm="right">
            <b-form-input v-model="form.company" type="text" id="company" required></b-form-input>
          </b-form-group>
          <b-form-group id="emailFormGroup" label="Email address" label-for="email" label-cols-sm="2"
            label-align-sm="right">
            <b-form-input v-model="form.email" type="email" id="email" required></b-form-input>
          </b-form-group>
          <b-form-group id="messageFormGroup" label="Message" label-for="message" label-cols-sm="2"
            label-align-sm="right">
            <b-form-textarea v-model="form.message" id="message" rows="3" required></b-form-textarea>
          </b-form-group>
          <div class="text-right">
            <b-button type="submit" variant="outline-danger">Submit</b-button>
          </div>
        </b-form>
      </b-modal>
    </div>
  </section>
</template>



<script>
import axios from "axios";
// Import BootstrapVue components
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
} from "bootstrap-vue-3";

export default {
  name: "ContactSection",
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
  },
  data() {
    return {
      showModal: false,
      form: {
        name: "",
        company:"",
        email: "",
        message: "",
      },
      submissionStatus: null, // Added to track submission status
    };
  },
  methods: {
    async handleSubmit() {
      this.submissionStatus = "submitting"; // Set status to 'submitting'

      try {
        const response = await axios.post(
          process.env.VUE_APP_CONTACT_API_URL,
          this.form
        );

        // Check response from Lambda function
        if (response.status === 200) {
          this.submissionStatus = "success"; // Set status to 'success'
          alert("Form submitted successfully!");
          this.form = { name: "", company:"", email: "", message: "" };
        } else {
          this.submissionStatus = "error"; // Set status to 'error'
          throw new Error("There was an error submitting your form.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        this.submissionStatus = "error"; // Set status to 'error'
        alert(error.message); // Use a more informative error message from the server if available
      }
    },
  },
};
</script>


<style >
  form label {
    font-weight: 600;
    color:#495057;
    font-family: 'Lato';
  }

modal-header{
  background-color: red;
}
</style>

