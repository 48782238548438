<template>
  <section class="product-banner">
    <picture>

      <img src ="@/assets/img/product-banner-bg(2).jpg"
      alt="Product Banner"
      style=""
      class="banner-image"
      />
    </picture>
    <div class="text-overlay">
      <h1>
        Supporting Healthcare Professionals with Essential Supplies
      </h1>
      <p class="lead" v-if="!$route.params.category">
        Explore our extensive catalog of high-quality medical supplies and equipment designed to meet the needs of
        healthcare professionals.
      </p>
      <p v-else class="lead">
        Browse our comprehensive selection of
        <span class="category-name">{{ formatCategoryName($route.params.category) }}</span>
      </p>
      <router-link to="/products" class="btn btn-primary btn-sm">
        View All Products
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProductBanner",
  methods: {
    formatCategoryName(category) {
      // Split the category string by hyphens, capitalize each word, and join them back together
      return category.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
  }
};
</script>

<style scoped>
.banner-image-container {
  height: 40vh;
  max-width: 100%;
  /* Ensure it doesn't exceed viewport width */
}
/* Product Banner */
.product-banner {
  background-color: #f2f2f2;
  /* Light blue background */
  position: relative;
  min-height: 40vh;
  /* Adjust height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
/* Text Overlay */
.text-overlay {
  position:relative;
  z-index: 2;
  text-align: center;
  padding: 1rem;
  border-radius: 0.2rem;
  width: 100%;
  background-color: rgba(31, 70, 65, 0.7);
  min-height: 50vh;
  padding-top: 6rem;
  /* Slightly lighter overlay */
}

.text-overlay h1 {
  font-size: 2.0rem;
  /* Adjust font size for optimal impact */
  margin-bottom: 1.5rem;
  color: #fff;
  font-family: 'Lato', sans-serif;
  /* Use Lato font if available */
  font-weight: 400;
  /* Bolder font weight */
  
}

.lead {
  font-size: 1.4rem;
  margin-bottom: 2rem;
  color: #fff;
  /* White text for better contrast */
  font-family: 'Lato', sans-serif;
}

.category-name {
  font-weight: bold;
  /* Make it bold */
  color: #ffc107;
  /* Or any color you prefer */
  text-decoration: underline;
  /* Add an underline */
}

/* Button Styling */
.btn-primary {
    background-color: #4D8886;
      border-color: #4D8886;
  
  font-weight: bold;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  /* Smooth transition */
  border-radius: 5px;
}

.btn-primary:hover {
background-color: #ffc107;
  /* Bootstrap primary color */
  border-color: #ffc107;
  /* Slightly darker primary color on hover */
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
  /* Increased shadow on hover */
}
/* Responsive Adjustments */
@media (max-width: 768px) {
  .text-overlay {
    padding: 0.4rem;
  }

  .text-overlay h1 {
    font-size: 1.8rem;
    margin-bottom: 1.2rem;
    padding-top: 4rem;
  }

  .lead {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }

  .btn-primary.btn-sm{
    padding: 0.5rem 0.5rem;
    font-size: 1.2rem;
    border-radius: 5px;
  }
  
}
</style>
