<template>
    <nav class="navbar navbar-expand-lg bg-light navbar-light py-3 sticky-top">
        <div class="container">
            <router-link to="/" class="navbar-brand">
                <img src="@/assets/img/logoDevafriHealth.png" alt="DevAfri Health Logo" width="50" height="50" />
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navmenu">
                <span class="navbar-toggler-icon"></span>

            </button>
            <div class="collapse navbar-collapse" id="navmenu">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">

                        <router-link to="/" class="nav-link">Home</router-link>
                    </li>
                    <li class="nav-item dropdown">
                        <a href="#" class="nav-link dropdown-toggle  
 custom-dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Products</a>
                        <ul class="dropdown-menu custom-dropdown-menu">
                            <li><router-link class="dropdown-item"
                                    to="/products/disposable-protective-apparel">Disposable Protective
                                    Apparel</router-link></li>
                            <li><router-link class="dropdown-item" to="/products/exam-gloves">Exam Gloves</router-link>
                            </li>
                            <li><router-link class="dropdown-item" to="/products/fall-management-footwear">Fall
                                    Management Footwear</router-link></li>
                            <li><router-link class="dropdown-item" to="/products/incontinence-products">Incontinence
                                    Products</router-link></li>
                            <li><router-link class="dropdown-item" to="/products/pressure-infusion-bags">Pressure
                                    Infusion Bags</router-link></li>
                            <li><router-link class="dropdown-item"
                                    to="/products/stethoscopes">Stethoscopes</router-link></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <router-link to="/contact" class="nav-link">Contact Us</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>

export default {
    name: "NavBar",
    props: {
        title: {
            type: String,
            required: true,
        },

    },

};
</script>


<style scoped>
/* Dropdown Container */
.custom-dropdown-menu {
    background-color: #f8f9fa;
    /* Light background color */
    border: none;
    /* Remove default border */
    border-radius: 8px;
    /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
}

/* Dropdown Toggle */
.custom-dropdown-toggle {
    color: #343a40;
    /* Dark text color */
}

.custom-dropdown-toggle:hover {
    color: #007bff;
    /* Primary color on hover */
    background-color: transparent;
    /* No background change on hover */
}

/* Dropdown Items */
.custom-dropdown-menu .dropdown-item {
    color: #343a40;
    /* Dark text color */
    padding: 0.75rem 1.5rem;
    /* Adjust padding if needed */
}

.custom-dropdown-menu .dropdown-item:hover {
    background-color: #e2e6ea;
    /* Light grey background on hover */
    color: #007bff;
    /* Primary color on hover */
}
/* Navbar Link Color */
.navbar-nav .nav-link {
    /* Targets all links within the navbar */
    color: var(--bs-teal);
    /* Sets the text color to your teal variable */
}

/* Optional: Hover state for links */
.navbar-nav .nav-link:hover {
    color: darken(var(--bs-teal), 10%);
    /* Slightly darker teal on hover */
}
</style>